.root {
  .buttonContainer {
    position: absolute;
    bottom: calc(var(--spacing) * 3);
    width: calc(100% - calc(var(--spacing) * 4));
  }

  .popUpHeaderTitle {
    display: flex;
    align-items: center;
  }

  .clearButton {
    margin-bottom: var(--spacing);
  }
}
