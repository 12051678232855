@import '../../styles/breakpoints';

.root {
  & {
    background-color: var(--color--mono-white);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: unset;
  }

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    margin-bottom: calc(var(--spacing) * 2);
    gap: calc(var(--spacing) * 2);
    z-index: 10;

    .filterContainer {
      display: flex;
      flex-direction: column;
      gap: calc(var(--spacing) * 3);

      @include sm {
        width: 100%;
      }

      .filterDropdowns > div {
        width: 240px;
        @include sm {
          width: 100%;
        }
      }

      .filterDropdowns,
      .activeFilters {
        display: flex;
        gap: calc(var(--spacing) * 1.5);

        @include sm {
          width: 100%;
        }
      }
    }
  }

  .content {
    display: grid;
    grid-template-columns: auto 520px;
    grid-gap: 24px;
    flex-grow: 1;
    height: 80%;
    position: relative;

    @include lg {
      display: flex;
      flex-direction: column;
      grid-template-columns: 1fr;
      grid-gap: 0px;
    }

    .resetCtaWrapper {
      margin-top: calc(var(--spacing) * 3);
    }
  }

  .map {
    border-top-left-radius: calc(var(--spacing) * 1.5);
    border-bottom-left-radius: calc(var(--spacing) * 1.5);
    position: relative;

    @include lg {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .mapWrapper {
    position: relative;

    @include xl {
      flex: 1;
    }

    @include xs {
      margin: 0 calc(var(--spacing) * -1);
    }
  }

  .selectionResetButton {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--color--action-contrast-fill);
    border: none;

    &:hover {
      background-color: var(--color--secondary-main);
    }
  }

  .legend {
    position: absolute;
    bottom: 12px;
    left: 12px;

    @include md {
        left: 8px;
        bottom: 8px;
    }
  }

  .articleList {
    @include lg {
      height: 156px;
    }

    @include sm {
      height: 106px;
    }

    @include xs {
      height: fit-content;
    }
  }
}
