.root {
  padding-top: calc(var(--spacing) * 3);
  position: relative;

  .filter {
    margin-bottom: calc(var(--spacing) * 6);
    position: relative;

    z-index: 10;

    @media print {
      display: none;
    }
  }

  .loadArticlesButton {
    display: flex;
    justify-content: center;
    margin-top: calc(var(--spacing) * 5);

    @media print {
      display: none;
    }
  }

  .filterBar {
    display: flex;
    gap: calc(var(--spacing) * 1.5);
    flex-wrap: wrap;
  }

  .hover {
    cursor: pointer;
  }

  .searchIndicator {
    margin-bottom: calc(var(--spacing) * 5);
  }

  .scrollToContainer {
    position: absolute;
    top: -100px;
  }
}
