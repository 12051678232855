@import '../../styles/breakpoints';
@import 'node_modules/@dreipol/pusch-components/scss/mixins';

.root {
  max-width: 832px;
  margin: 0 auto;

  .glossaryContainer {
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing) * 3);

    @include sm {
      gap: calc(var(--spacing) * 2);
    }

    * > p {
      margin: 0;
    }

    .accordionContent > * {
      margin-bottom: calc(var(--spacing) * 1.5);
      margin-top: 0;
    }

    .accordionContent li::marker {
      font-size: 14px;
    }

    .accordionContent ul {
      width: calc(100% - (var(--spacing) * 4));
      margin-left: calc(var(--spacing) * 4);
      margin-top: 0 !important;

      @include xs {
        margin-left: calc(var(--spacing) * 2);
      }
    }

    .accordionContent ol {
      margin-top: 0 !important;

      @include xs {
        padding-left: calc(var(--spacing) * 2);
      }
    }

    .accordionContent * a > span {
      @include typo-paragraph2;

      @include md {
        @include typo-paragraph2-small;
      }
    }

    .accordionContent br {
      display: block;
      content: '';
      margin-top: 24px;

      @include md {
        margin-top: 17px;
      }
    }
  }
}
