@import '../../styles/breakpoints';

.root {
  & {
    max-width: 100vw;
    height: 100%;
    overflow: auto;
    position: relative;

    @include lg {
      padding-top: calc(var(--spacing) * 2);
      overflow: hidden;
    }

    @include xs {
      padding-top: var(--spacing);
    }
  }

  .swiperList {
    height: 140px;

    @include sm {
      height: 90px;
    }

    @include xs {
      height: unset;
    }
  }

  .list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .noArticleWarning {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navigationButtonWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    align-items: center;
    position: absolute;
    top: 0;
    z-index: 5;

    @include xs {
      width: unset;
      height: unset;
      align-items: unset;
      position: unset;
      margin-top: calc(var(--spacing) * 0.5);
    }
  }

  .navigationButton {
    &.navigationButtonDisabled {
      opacity: 0.1;
    }
  }

  .listItem.listItem {
    transition: opacity var(--trs--timing-fast) var(--trs--easing);

    & + .listItem {
      margin-top: calc(var(--spacing) * 2);
    }

    @include lg {
      & + .listItem {
        margin-top: 0;
      }
    }
  }
}
