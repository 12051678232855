.root {
  &.column {
    & > * + * {
      margin-top: calc(var(--spacing) * var(--stack--space, 1));
    }
  }

  &.row {
    display: flex;
    flex-direction: row;

    & > * + * {
      margin-left: calc(var(--spacing) * var(--stack--space, 1));
    }
  }
}
