.hideTooltip {
  display: none;
}

.showTooltip {
  display: block;
}

.tooltip {
  padding: calc(var(--spacing) * 1.5) calc(var(--spacing) * 2);
  background-color: var(--color--mono-white);
  width: 300px;
  margin-top: 5px;
  margin-left: -150px;
  border-radius: 6px;
  z-index: 1000;
  position: relative;
  filter: drop-shadow(0px 1px 5px rgba(35, 31, 32, 0.16))
    drop-shadow(0px 2px 10px rgba(35, 31, 32, 0.2));
}
