@import '../../styles/breakpoints';
@import 'node_modules/@dreipol/pusch-components/scss/mixins';

.textVideoRoot {
  --xxl-padding: var(--spacing) * 27;
  --xl-padding: var(--spacing) * 24;
  --lg-padding: var(--spacing) * 20;
  --md-padding: var(--spacing) * 15;
  --header-img-padding: var(--spacing) * 4;

  margin-bottom: calc(var(--spacing) * 7);

  @include md {
    margin-bottom: calc(var(--spacing) * 5);
  }

  width: calc(100% + var(--xxl-padding) - var(--header-img-padding));

  @include xl {
    width: calc(100% + var(--xl-padding) - var(--header-img-padding));
  }

  @include lg {
    width: calc(100% + var(--lg-padding) - var(--header-img-padding));
  }

  @include md {
    width: calc(100% + var(--md-padding) - var(--header-img-padding));
  }

  @include xs {
    width: calc(100% + calc(var(--spacing) * 4));
  }
}

.headingVideoRoot {
  width: 100%;
  background: #000;
  display: flex;
  align-items: center;
  aspect-ratio: 3/2;
}

.video {
  width: 100%;
  aspect-ratio: 3/2;
  overflow-y: hidden;
  overflow-x: hidden;
  border: none;
}

.video.facebook {
  aspect-ratio: 16/9;
}

.textVideo {
  aspect-ratio: 16/9;
}

.caption {
  text-align: right;
  padding-top: calc(var(--spacing) * 1.5);

  @include xs {
    padding-right: calc(var(--spacing) * 2);
    padding-left: calc(var(--spacing) * 4);
  }

  .sourceLink {
    padding-left: 6px;

    @include xs {
      @include typo-paragraph2-small;
    }
  }
}
