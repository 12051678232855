@import '../../styles/breakpoints';
@import 'node_modules/@dreipol/pusch-components/scss/mixins';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: calc(var(--spacing) * 7);

  @include md {
    margin-bottom: calc(var(--spacing) * 5);
  }

  @include xs {
    width: calc(100% + calc(var(--spacing) * 4));
  }

  .imageContainer {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .selectedImage {
      max-height: 552px;
      width: 100%;
      object-fit: contain;
      aspect-ratio: 3/2;
      height: auto;
    }

    .nextButton {
      margin-right: calc(var(--spacing) * -6);
      transform: rotate(-90deg);
      border: none;
      z-index: 2;

      @include xs {
        position: absolute;
        margin-right: 0px;
        right: calc(var(--spacing) * 1);
      }
    }

    .backButton {
      margin-left: calc(var(--spacing) * -6);
      transform: rotate(90deg);
      border: none;
      z-index: 2;

      @include xs {
        position: absolute;
        margin-right: 0px;
        left: calc(var(--spacing) * 7);
      }
    }
  }

  .hidden {
    display: none;
  }

  .counter {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    height: calc(var(--spacing) * 3);
    background-color: var(--color--action-contrast-fill);
    border-radius: 16px;
    color: white;
    left: calc(50% - 42px / 2 + 0.5px);
    bottom: calc(var(--spacing) * 2);
    position: absolute;
    @include typo-label3;
  }

  .caption {
    align-self: flex-end;
    text-align: right;
    padding-top: calc(var(--spacing) * 1.5);

    @include xs {
      padding-right: calc(var(--spacing) * 2);
    }

    .sourceLink {
      padding-left: 6px;

      @include xs {
        @include typo-paragraph2-small;
      }
    }
  }

  @keyframes slideInRight {
    from {
      transform: translateX(20px);
      opacity: 0.8;
    }

    to {
      transform: translate(0);
      opacity: 1;
    }
  }

  @keyframes slideInLeft {
    from {
      transform: translateX(-20px);
      opacity: 0;
    }

    to {
      transform: translate(0);
      opacity: 1;
    }
  }

  .back {
    animation-duration: 0.6s;
    animation-name: slideInLeft;
    animation-timing-function: ease-out;
  }

  .forward {
    animation-duration: 0.6s;
    animation-name: slideInRight;
    animation-timing-function: ease-out;
  }
}
