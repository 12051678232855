@import 'node_modules/@dreipol/pusch-components/scss/mixins';
@import '../../styles/breakpoints';

.root {
  display: grid;
  grid-template-columns: 400px 1fr 1fr;
  gap: calc(var(--spacing) * 4);

  @include xl {
    grid-template-columns: 344px 1fr 1fr;
  }
  @include lg {
    gap: calc(var(--spacing) * 3);
    grid-template-columns: 292px 1fr 1fr;
  }

  @include md {
    column-gap: calc(var(--spacing) * 2.5);
    row-gap: calc(var(--spacing) * 1);
    grid-template-columns: 280px 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      'left top-right'
      'left bottom-right ';
  }

  @include xs {
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing) * 2);
  }

  @media print {
    display: grid;
    column-gap: calc(var(--spacing) * 2.5);
    row-gap: calc(var(--spacing) * 1);
    grid-template-columns: 280px 1fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      'left top-right'
      'left bottom-right ';
  }

  .imageWrapper {
    width: 100%;
    aspect-ratio: 3/2;

    @include lg {
      aspect-ratio: 1/1;
    }
    @include xs {
      aspect-ratio: 3/2;
    }
  }

  .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .imageLink {
    display: flex;
    width: 100%;
    @include sm {
      width: 100%;
    }
  }

  .imageContainer {
    width: calc(100% + 32px);
    margin-top: -16px;
    margin-left: -16px;
    margin-bottom: -16px;

    @include md {
      grid-area: left;
      margin: 0;
      width: 100%;
      aspect-ratio: 1/1;
    }

    @include xs {
      aspect-ratio: 3/2;
    }

    @media print {
      grid-area: left;
      margin: 0;
    }
  }

  .middleContainer {
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing) * 1.5);
    padding-top: calc(var(--spacing) * 2);
    height: fit-content;

    @include md {
      gap: calc(var(--spacing));
      grid-area: top-right;
    }

    @include xs {
      padding-top: 0;
    }

    @media print {
      gap: calc(var(--spacing));
      grid-area: top-right;
    }

    .delimiter {
      padding-right: 3px;
      display: inline-block;
    }

    .metaInfoContainer {
      display: flex;
      align-items: center;
      gap: var(--spacing);

      @include md {
        @include typo-paragraph2-small;
      }

      .authorsContainer {
        display: flex;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;

        .authorLink {
          height: auto;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .title {
      @include typo-h4;
      text-align: left;

      & span {
        white-space: pre-wrap;
      }

      @include xl {
        @include typo-h5;
      }

      @include md {
        @include typo-h4-small;
      }
    }

    .categoryContainer {
      display: flex;
      gap: var(--spacing);
      padding: var(--spacing) 0;
      flex-wrap: wrap;

      .filter {
        display: inline;
      }
    }
  }

  .teaserContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: calc(var(--spacing) * 2);
    padding-bottom: 21px;

    @include xl {
      padding-bottom: 18px;
    }

    @include md {
      padding-top: 0;
      padding-bottom: 14px;
      grid-area: bottom-right;
    }

    @include xs {
      justify-content: inherit;
      gap: calc(var(--spacing) * 1.5);
      padding: 0;
    }

    @media print {
      padding-top: 0;
      padding-bottom: 14px;
      grid-area: bottom-right;
    }

    .teaserText {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;

      > * p {
        margin: 0;
        display: inline;
      }

      @include xl {
        -webkit-line-clamp: 6;
      }

      @include lg {
        -webkit-line-clamp: 8;
      }

      @include md {
        -webkit-line-clamp: 3;
        @include typo-paragraph2-small;
      }

      @include xs {
        -webkit-line-clamp: 5;
      }

      @media print {
        -webkit-line-clamp: 3;
      }
    }

    .toggleArticle {
      margin-top: calc(var(--spacing) * 2);
      width: auto;
      height: auto;
      justify-content: flex-start;
      gap: var(--spacing);
      color: var(--color--text-primary);
      @include typo-button;

      @include md {
        margin-top: var(--spacing);
      }

      @include xs {
        margin: 0;
      }

      @media print {
        margin-top: var(--spacing);
      }
    }
  }
}
