$breakpoint-xs: 575;
$breakpoint-sm: 767;
$breakpoint-md: 991;
$breakpoint-lg: 1199;
$breakpoint-xl: 1359;

@mixin xs {
  @media (max-width: #{$breakpoint-xs - 1}px) {
    @content;
  }
}

@mixin sm {
  @media (max-width: #{$breakpoint-sm - 1}px) {
    @content;
  }
}

@mixin md {
  @media (max-width: #{$breakpoint-md - 1 }px) {
    @content;
  }
}

@mixin lg {
  @media (max-width: #{$breakpoint-lg - 1 }px) {
    @content;
  }
}

@mixin xl {
  @media (max-width: #{$breakpoint-xl - 1 }px) {
    @content;
  }
}

@mixin xxl {
  @media (min-width: #{$breakpoint-xl + 1 }px) {
    @content;
  }
}

@mixin minMd {
  @media (min-width: #{$breakpoint-sm }px) {
    @content;
  }
}

@mixin minLg {
  @media (min-width: #{$breakpoint-md }px) {
    @content;
  }
}
