@import '../../styles/breakpoints';
@import 'node_modules/@dreipol/pusch-components/scss/mixins';

.root {
  max-width: 616px;
  width: 100%;
  margin-bottom: calc(var(--spacing) * 7);

  @include md {
    margin-bottom: calc(var(--spacing) * 5);
  }

  .accordionContent.accordionContent > * {
    margin-bottom: calc(var(--spacing) * 1.5);
    margin-top: 0;
  }

  .accordionContent li::marker {
    font-size: 14px;
  }

  .accordionContent ul {
    width: calc(100% - (var(--spacing) * 4));
    margin-left: calc(var(--spacing) * 4);
    margin-top: 0 !important;

    @include xs {
      margin-left: calc(var(--spacing) * 2);
    }
  }

  .accordionContent ol {
    margin-top: 0 !important;

    @include xs {
      padding-left: calc(var(--spacing) * 2);
    }
  }

  .accordionContent br {
    display: block;
    content: '';
    margin-top: 24px;

    @include md {
      margin-top: 17px;
    }
  }

  .accordionContent * a > span {
    @include typo-paragraph2;

    @include md {
      @include typo-paragraph2-small;
    }
  }
}
