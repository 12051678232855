@import '../../styles/breakpoints';

.root {
  --artifact--accent: var(--color--mono-50);
  --artifact--color: var(--color--mono-100);
  --artifact--fill: 50%;

  position: relative;
  overflow: hidden;

  @media print {
    max-width: 70vw;
  }

  &.fill {
    .childrenContainer {
      width: 100%;
      height: 100%;
    }
  }

  &.contained {
    background-color: var(--artifact--color);

    @include sm {
      background-color: transparent;
    }

    @include minMd {
      &:focus-within,
      &:hover {
        .element {
          width: 130%;
        }
      }
    }
  }

  &.appearOnHover,
  &.fixed {
    padding: 16px;
    z-index: 5;

    .element.appearOnHover {
      opacity: 0;
      margin-left: -16px;
    }

    @include md {
      margin-left: 0;
    }

    .childrenContainer {
      z-index: 15;
      position: relative;
      display: flex;
    }

    &:hover .element.appearOnHover,
    .element.fixed {
      z-index: 0;
      background: linear-gradient(
        130deg,
        var(--artifact--accent) 0%,
        var(--artifact--accent) 44%,
        rgba(255, 255, 255, 0) 44%
      );
      width: 100%;
      height: 100%;
      opacity: 1;
      transition: opacity 1s;

      @include lg {
        background: linear-gradient(
          125deg,
          var(--artifact--accent) 0%,
          var(--artifact--accent) 48%,
          rgba(255, 255, 255, 0) 48%
        );
      }
    }

    &:hover .element.appearOnHover {
      @include md {
        display: none;
      }
    }
  }

  &.appearOnHover {
    @include md {
      padding: 0;
    }
  }

  &.fixed {
    @include xs {
      width: calc(100% + calc(var(--spacing) * 6));
      padding-left: 0;
      padding-right: 0;
      overflow-x: hidden;
    }

    @media print {
      padding: 16px;
      width: 100%;
      overflow: hidden;
    }
  }

  .element {
    position: absolute;
    top: 0;
    left: 0;

    &.contained {
      content: '';
      background: linear-gradient(
        135deg,
        var(--artifact--accent) 0%,
        var(--artifact--accent) var(--artifact--fill),
        rgba(255, 255, 255, 0) var(--artifact--fill)
      );
      width: 66%;
      aspect-ratio: 1/1;
      transition: background var(--trs--easing) 0.8s,
        width var(--trs--easing) 0.8s, height var(--trs--easing) 0.8s;

      @include sm {
        width: 365px;
      }

      @include xs {
        height: 100%;
        background: linear-gradient(
          125deg,
          var(--artifact--accent) 0%,
          var(--artifact--accent) var(--artifact--fill),
          rgba(255, 255, 255, 0) var(--artifact--fill)
        );
      }
    }
  }

  &.green {
    --artifact--accent: var(--color--accent-green-main);
    --artifact--color: var(--color--accent-green-light);
  }

  &.yellow {
    --artifact--accent: var(--color--accent-yellow-main);
    --artifact--color: var(--color--accent-yellow-light);
  }

  &.brown {
    --artifact--accent: var(--color--accent-brown-main);
    --artifact--color: var(--color--accent-brown-light);
  }

  &.red {
    --artifact--accent: var(--color--accent-red-main);
    --artifact--color: var(--color--accent-red-light);
  }

  &.blue {
    --artifact--accent: var(--color--accent-blue-main);
    --artifact--color: var(--color--accent-blue-light);
  }

  &.orange {
    --artifact--accent: var(--color--accent-orange-main);
    --artifact--color: var(--color--accent-orange-light);
  }

  &.active {
    .element.appearOnHover {
      z-index: 0;
      background: linear-gradient(
        130deg,
        var(--artifact--accent) 0%,
        var(--artifact--accent) 44%,
        rgba(255, 255, 255, 0) 44%
      );
      width: 100%;
      height: 100%;
      opacity: 1;
      transition: opacity 1s;

      @include lg {
        background: linear-gradient(
          125deg,
          var(--artifact--accent) 0%,
          var(--artifact--accent) 48%,
          rgba(255, 255, 255, 0) 48%
        );
      }
    }
  }
}
