@import '../../styles/breakpoints';

.root {
  margin-bottom: calc(var(--spacing) * 9);
  margin-top: 0;
  border: solid 0.5px var(--color--mono-200);
  width: 100%;

  @include md {
    margin-bottom: calc(var(--spacing) * 6.5);
  }

  @media print {
    display: none;
  }
}
