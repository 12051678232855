@import '../../styles/breakpoints';

.root {
  padding: calc(var(--spacing) * 6);

  @include md {
    margin-right: -16px;
    padding: calc(var(--spacing) * 3);
  }

  @include sm {
    background: transparent;
    padding: calc(var(--spacing) * 2);
  }

  @media print {
    break-inside: avoid;
  }

  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    z-index: 5;
    gap: 32px;

    @include sm {
      display: flex;
      flex-direction: column;
      padding: calc(var(--spacing) * 3);
      background: var(--focus-article--color);
    }
  }

  .text {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    margin-bottom: calc(var(--spacing) * 2);

    @include md {
      margin-bottom: calc(var(--spacing) * 1.5);
    }
  }

  .leadContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .headerWrapper {
  }

  .ctaWrapper {
    margin-top: calc(var(--spacing) * 2);
  }

  .title {
    margin-bottom: calc(var(--spacing) * 2.5);
  }

  .tagWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing);
  }

  &.blue {
    --focus-article--color: var(--color--accent-blue-light);
  }

  &.orange {
    --focus-article--color: var(--color--accent-orange-light);
  }

  &.green {
    --focus-article--color: var(--color--accent-green-light);
  }

  &.yellow {
    --focus-article--color: var(--color--accent-yellow-light);
  }

  &.brown {
    --focus-article--color: var(--color--accent-brown-light);
  }

  &.red {
    --focus-article--color: var(--color--accent-red-light);
  }
}

.artifactContainer {
  width: 100%;
  height: fit-content;
}
