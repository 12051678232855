@import 'node_modules/@dreipol/pusch-components/scss/mixins';
@import '../../styles/breakpoints';

.root.root.root.root {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  margin-bottom: 0;

  @include md {
    -webkit-line-clamp: 6;
    margin-bottom: 0;
  }
}

.content.content {
  margin-bottom: var(--spacing);

  &:last-of-type {
    margin-bottom: 0;
  }
}

.link {
  @include typo-label1;
  color: var(--color--secondary-contrast);
  text-decoration: underline;
}
