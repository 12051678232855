@import '../../styles/breakpoints';

.root {
  --overlay--background: rgba(0, 0, 0, 0.6);

  &.fill {
    .contentWrapper {
      width: 100%;
      height: 100%;
    }
  }

  &.fullscreen {
    .contentWrapper {
      width: 100%;
      min-height: 100%;
      padding: 0;
    }

    .content {
      border-radius: 0;
    }
  }

  &.centered {
    .contentWrapper {
    }

    .content {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }

  & {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: var(--overlay--background-index, 20);
    display: flex;
    flex-direction: column;
  }

  .background {
    content: '';
    z-index: var(--overlay--background-index, 20);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--overlay--background);
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex: 1 0 100%;
    z-index: var(--overlay--content-index, 21);
    position: absolute;
    box-sizing: border-box;
    padding: calc(var(--spacing) * 3);

    @include lg {
      padding: calc(var(--spacing) * 1.5);
    }
  }

  .content {
    padding: calc(var(--spacing) * 2);
    border-radius: calc(var(--spacing) * 1.5);

    @include xs {
      padding: var(--spacing);
    }
  }
}
