.root {
  --filter-indicator--color: transparent;
  --filter-indicator--size: 14px;

  & {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .indicator {
    content: '';
    width: var(--filter-indicator--size);
    height: var(--filter-indicator--size);
    background: var(--filter-indicator--color);
    border-radius: 4px;
    margin-right: calc(var(--spacing) * 0.5);
    border: 2px solid rgba(0, 0, 0, 0.2);
  }

  .label {
    line-height: 1;
  }

  &.yellow {
    --filter-indicator--color: var(--color--accent-yellow-main);
  }

  &.blue {
    --filter-indicator--color: var(--color--accent-blue-main);
  }

  &.orange {
    --filter-indicator--color: var(--color--accent-orange-main);
  }

  &.red {
    --filter-indicator--color: var(--color--accent-red-main);
  }

  &.brown {
    --filter-indicator--color: var(--color--accent-brown-main);
  }

  &.green {
    --filter-indicator--color: var(--color--accent-green-main);
  }
}
