@import '../../styles/breakpoints';
@import 'node_modules/@dreipol/pusch-components/scss/mixins';

.root {
  position: relative;
  z-index: 20;
}

.headerBar {
  background-color: white;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
  padding-top: calc(var(--spacing) * 3.5);
  padding-bottom: calc(var(--spacing) * 3.5);
  box-sizing: border-box;

  @include lg {
    display: flex;
    justify-content: space-between;
  }

  @include md {
    padding-top: calc(var(--spacing) * 2.5);
    padding-bottom: calc(var(--spacing) * 2.5);
  }
}

.logo {
  width: auto;
  height: auto;
}

.anniversaryBanner {
  left: calc((var(--spacing) * -5) - 98px);
  top: calc(var(--spacing) * -4);
  position: absolute;
  height: auto;

  @include lg {
    left: calc((var(--spacing) * -2) - 98px);
  }

  @include md {
    top: calc(var(--spacing) * -2.5);
    left: calc((var(--spacing) * -4) - 76px);
  }

  @include xs {
    left: calc((var(--spacing) * -2) - 76px);
  }
}

.navDesktop {
  display: flex;
  align-items: center;
  @include md {
    display: none;
  }
}

.navContainer {
  display: flex;
  align-items: center;

  @include md {
    padding: calc(var(--spacing) * 3.5) calc(var(--spacing) * 5.5);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    position: absolute;
    height: calc((var(--vh, 1vh) * 100) - var(--spacing) * 11);
    width: 100%;
    background-color: white;
    z-index: 17;
  }
}

.navMobile {
  display: block;

  @include minLg {
    display: none;
  }
}

.navToggle.navToggle {
  display: none;
  @include md {
    display: block;
  }
}

.mobileNavCta.mobileNavCta {
  display: none;
  @include md {
    display: flex;
  }
}

.mobileCtaLink {
  width: 100%;
}

.nav {
  display: flex;
  gap: calc(var(--spacing) * 5);
  align-items: center;

  @include lg {
    gap: calc(var(--spacing) * 3);
  }

  @include md {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.navItem.navItem {
  @include typo-paragraph1;
  cursor: pointer;
  width: max-content;

  @include md {
    @include typo-h6;
  }
}

.leftAlign {
  justify-self: left;

  .logo {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.rightAlign {
  justify-self: right;
  display: flex;
  align-items: center;
  position: relative;
}

.cta.cta {
  @include md {
    display: none;
  }
}

.languageSwitcher {
  margin-right: calc(var(--spacing) * 3);

  @include lg {
    margin-right: calc(
      var(--spacing) * 2
    ); //Switch back to 24 pixel when anniversary banner is not used anymore
  }

  @include md {
    display: none;
  }

  &.languageSwitcherOpen {
    display: block;
  }
}

@media print {
  .root {
    display: none;
  }
}
