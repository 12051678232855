@import '../../styles/breakpoints';
@import 'node_modules/@dreipol/pusch-components/scss/mixins';

//list css
.list {
  padding: 0;
  gap: 12px;
  margin-bottom: calc(var(--spacing) * 7);

  @include md {
    margin-bottom: calc(var(--spacing) * 5);
  }

  li > p {
    display: inline;
    width: 100%;
    position: relative;
    left: 4px;
    margin: 0;
  }

  li:not(:last-child) {
    margin-bottom: calc(var(--spacing) * 1.5);

    @include md {
      margin-bottom: 5px;
    }
  }
}

ol.list {
  margin-top: 0;
  width: 100%;
  padding-left: 33px;

  li::marker {
    @include typo-paragraph1;
    font-weight: 700;

    @include md {
      @include typo-paragraph1-small;
    }
  }
}

ul.list {
  list-style-position: outside;
  width: calc(100% - calc(18px + var(--spacing) * 3));
  margin-left: calc(var(--spacing) * 3);
  margin-top: 0;

  li::marker {
    font-size: 22px;
  }
}

p.pMargin + .list {
  margin-top: calc(var(--spacing) * -5);

  @include md {
    margin-top: calc(var(--spacing) * -3.5);
  }
}

.list.list + p {
  margin-top: calc(var(--spacing) * -5);

  @include md {
    margin-top: calc(var(--spacing) * -3.5);
  }
}

//headings css
.heading {
  text-align: left;
  width: 100%;
}

.h1 {
  text-align: center;
}

h4.h4Margin {
  margin-bottom: calc(var(--spacing) * 3);

  @include md {
    margin-bottom: calc(var(--spacing) * 2);
  }
}

h5.h5Margin {
  margin-bottom: calc(var(--spacing) * 2);

  @include md {
    margin-bottom: calc(var(--spacing) * 1.5);
  }
}

//paragraph css
p.pMargin {
  margin-bottom: calc(var(--spacing) * 7);
  width: 100%;

  @include md {
    margin-bottom: calc(var(--spacing) * 5);
  }
}

p.pMargin + p {
  margin-top: calc(var(--spacing) * -2.5);

  @include md {
    margin-top: calc(var(--spacing) * -2);
  }
}

p.pMargin subscript {
  display: inline;
  vertical-align: sub;
  font-size: smaller;
  position: relative;
  line-height: 0px;
  font-weight: 700;
}

p.pMargin superscript {
  display: inline;
  vertical-align: super;
  font-size: smaller;
  position: relative;
  line-height: 0px;
  font-weight: 700;
}

//text link css
.textLink {
  display: inline;
  white-space: normal;
  word-break: break-word;
}
