@import 'node_modules/@dreipol/pusch-components/scss/mixins';

@import '../../styles/breakpoints';
@import 'node_modules/@dreipol/pusch-components/scss/mixins';

.root {
  display: block;
  align-self: flex-start;
  width: 100%;
  margin-bottom: calc(var(--spacing) * 7);

  @include md {
    margin-bottom: calc(var(--spacing) * 5);
  }

  p,
  p > a > span {
    @include typo-paragraph2;
  }

  li::marker {
    font-size: 16px !important;
  }

  ul {
    width: calc(100% - (var(--spacing) * 4)) !important;
    margin-left: calc(var(--spacing) * 4) !important;
  }

  > * {
    margin-bottom: calc(var(--spacing) * 1.5) !important;
  }
}
