@import '../../styles/breakpoints';

.root {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: baseline;

    .legend {
        display: inline-flex;
        padding: 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        border-radius: 24px;
        background: var(--color--mono-white);
        opacity: 0;
        pointer-events: none;
        transition: opacity .4s;
        box-shadow: var(--shadow--2);

        &.isVisible {
            opacity: 1;
            pointer-events: all;
        }

        @include md {
            display: none;
        }
    }

    .legendItem {
        display: flex;
        width: 200px;
        align-items: center;
        gap: 24px;
    }

    .button {
        display: flex;
        height: 48px;
        color: var(--color--text-secondary);
        padding: 0 16px;
        align-items: center;
        gap: 8px;
        border-radius: 12px;
        background: var(--color--mono-white);
        border: none;
        cursor: pointer;
        box-shadow: var(--shadow--2);

        &:hover {
            color: var(--color--text-primary);
        }

        @include md {
            height: auto;
            padding: 10px;
        }
    }

    .buttonText {
        @include md {
            display: none;
        }
    }
}

//Needs to be outside block, because its used in the overlay and normal legend
.legendIconWrapper {
    display: flex;
    height: 65px;
    width: 56px;
    flex: 0 0 auto;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
}

.legendOverlay {
    display: flex;
    width: calc(100% - 32px);
    max-width: 480px;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    border-radius: 12px;
    background: var(--color--mono-white);
    box-shadow: var(--shadow--2);

    .legendOverlayHeader {
        position: relative;
        display: flex;
        height: 48px;
        justify-content: center;
        align-items: center;
        gap: 16px;
        align-self: stretch;
    }

    .legendOverlayClose {
        position: absolute;
        right: 0;
    }

    .legendOverlayContent {
        display: flex;
        padding: 24px;
        flex-direction: column;
        gap: 24px;
        border-radius: 6px;
        align-items: center;
        align-self: stretch;
        background: var(--color--mono-50);
    }

    .legendOverlayItem {
        display: flex;
        width: 216px;
        align-items: center;
        gap: 24px;
    }
}
