@import '../../styles/breakpoints';
@import 'node_modules/@dreipol/pusch-components/scss/mixins';

.root {
  hr {
    border: 0.5px solid var(--color--mono-100);
    margin: 0;
  }

  .footerContainer {
    padding-top: calc(var(--spacing) * 6);
    padding-bottom: calc(var(--spacing) * 6);
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing) * 14);

    @include md {
      gap: calc(var(--spacing) * 8);
    }
  }

  .topContainer {
    display: flex;
    justify-content: space-between;

    .topContainerLinks {
      gap: 27px;

      .link {
        height: auto;
      }

      @include md {
        gap: calc(var(--spacing) * 3);
      }
    }

    .contentCol {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @include md {
        @include typo-label2;
        line-height: 21px;
      }
    }

    @include md {
      display: grid;
      grid-template-columns: repeat(2, auto);
      grid-template-rows: repeat(3, auto);
      gap: calc(var(--spacing) * 5);
      grid-template-areas:
        'top top'
        'middle-left middle-right '
        'bottom-left bottom-right ';

      > :nth-child(1) {
        grid-area: top;
      }

      > :nth-child(2) {
        grid-area: middle-left;
      }

      > :nth-child(3) {
        grid-area: bottom-left;
      }

      > :nth-child(4) {
        justify-self: flex-end;
      }
    }

    @include sm {
      display: flex;
      flex-direction: column;
      gap: calc(var(--spacing) * 3);
    }

    .ctaButton {
      width: fit-content;
    }
  }

  .bottomContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: calc(var(--spacing) * 2);

    .alignRight {
      display: flex;
      gap: calc(var(--spacing) * 3);
      flex-wrap: nowrap;

      .socialMediaLink {
        color: var(--color--mono-100);

        &:hover {
          color: var(--color--secondary-main);
        }
      }
    }

    .alignLeft {
      display: flex;
      gap: calc(var(--spacing) * 5);
      flex-wrap: nowrap;
      align-items: center;

      .secondaryContentLink {
        height: auto;

        &:visited {
          color: inherit;
        }

        &:hover {
          text-decoration: underline;
        }
      }

      @include md {
        @include typo-label2;
        line-height: 21px;
      }

      @include sm {
        flex-wrap: wrap;
        gap: calc(var(--spacing) * 2);

        .copyRightElement {
          flex-basis: 100%;
        }
      }
    }

    @include sm {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

@media print {
  .root {
    display: none;
  }
}
