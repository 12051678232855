@import '../../styles/breakpoints';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;

  .icons {
    display: grid;
    grid-template-columns: 140px 140px 140px;

    @include lg {
      grid-template-columns: 140px;
      grid-gap: 24px;
    }
  }

  .legend {
    background: var(--color--mono-50);
    border-radius: 8px;
    padding: 24px;
    margin-top: calc(var(--spacing) * 6);

    @include lg {
      margin-top: calc(var(--spacing) * 5);
    }
  }

  .iconWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .legendTitle {
    margin-bottom: calc(var(--spacing) * 3);
  }
}
