@import '../../styles/breakpoints';
@import 'node_modules/@dreipol/pusch-components/scss/mixins';

.root {
  --structured-text-video--xxl-padding: var(--spacing) * 27;
  --structured-text-video--xl-padding: var(--spacing) * 24;
  --structured-text-video--lg-padding: var(--spacing) * 20;
  --structured-text-video--md-padding: var(--spacing) * 15;
  --structured-text-video--header-img-padding: var(--spacing) * 4;

  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: calc(var(--spacing) * 7);

  @include md {
    margin-bottom: calc(var(--spacing) * 5);
  }

  &.threeToTwo, &.oneToOne {
    @include xs {
      width: calc(100% + calc(var(--spacing) * 4));
    }
  }

  .imageContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    & .image.threeToTwo,
    &.threeToTwo .caption {
      width: calc(
        100% + var(--structured-text-video--xxl-padding) -
          var(--structured-text-video--header-img-padding)
      );

      @include xl {
        width: calc(
          100% + var(--structured-text-video--xl-padding) -
            var(--structured-text-video--header-img-padding)
        );
      }

      @include lg {
        width: calc(
          100% + var(--structured-text-video--lg-padding) -
            var(--structured-text-video--header-img-padding)
        );
      }

      @include md {
        width: calc(
          100% + var(--structured-text-video--md-padding) -
            var(--structured-text-video--header-img-padding)
        );
      }

      @include xs {
        width: 100%;
      }
    }

    & .image.threeToTwo {
      height: auto;
      aspect-ratio: 3/2;
      object-fit: cover;
    }

    & .image.oneToOne {
      max-width: 720px;
      width: 100%;
      height: auto;
      aspect-ratio: 1/1;
      object-fit: cover;

      @include xs {
        max-width: 480px;
      }
    }

    & .image.twoToThree {
      height: 720px;
      width: auto;
      aspect-ratio: 2/3;
      object-fit: cover;

      @include xs {
        max-height: 480px;
      }
    }

    .caption {
      text-align: right;
      padding-top: calc(var(--spacing) * 1.5);
    }

    &.oneToOne .caption {
      width: 720px;

      @include md {
        width: 100%;
      }
    }

    &.twoToThree .caption {
      width: 480px;

      @include xs {
        max-width: 320px;
      }
    }

    &.threeToTwo .caption, &.oneToOne .caption {
      @include xs {
        padding-right: calc(var(--spacing) * 2);
        padding-left: calc(var(--spacing) * 2);
      }
    }
  }
}
