@import '../../styles/breakpoints';
@import 'node_modules/@dreipol/pusch-components/scss/mixins';

.root {
  margin-bottom: calc(var(--spacing) * 7);
  width: 100%;
  display: flex;
  justify-content: center;

  @include md {
    margin-bottom: calc(var(--spacing) * 5);
  }

  .customEmbed {
    display: flex;
    flex-direction: column;
    width: 400px;
    color: inherit;
    border: 2px solid #f0efef;
    border-radius: 12px;
    box-sizing: content-box;

    @include xs {
      max-width: 343px;
      width: 100%;
    }

    &:hover {
      border-color: var(--color-secondary-main);
    }

    &:hover .textContainer .title {
      text-decoration: underline;
    }

    .textContainer {
      display: flex;
      flex-direction: column;
      gap: calc(var(--spacing) * 0.5);
      padding: calc(var(--spacing) * 3);

      .description {
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
      }
    }

    .image {
      width: 100%;
      height: 225px;
      object-fit: cover;
      border-radius: 12px 12px 0 0;

      @include xs {
        height: 193px;
      }
    }
  }

  .linkedInContainer {
    position: relative;

    .linkedInLogo {
      position: absolute;
      top: 12px;
      right: 20px;
    }
  }
}
