@import '../../styles/breakpoints';

.root {
  --teaser--background-color: var(--color--mono-50);
  --teaser--padding: calc(var(--spacing) * 6);
  padding: var(--teaser--padding);
  background-color: var(--teaser--background-color);
  transition: background-color var(--trs--easing) var(--trs--timing-fast);
  display: block;
  color: inherit;

  @include md {
    --teaser--padding: calc(var(--spacing) * 3);
  }

  @media print {
    break-inside: avoid;
  }

  & > * + * {
    margin-top: calc(var(--spacing) * 2);

    @include md {
      --teaser--padding: calc(var(--spacing) * 1.5);
    }

    @include sm {
      --teaser--padding: calc(var(--spacing) * 2);
    }
  }

  .body > p {
    margin-bottom: calc(var(--spacing) * 3);

    @include md {
      margin-bottom: calc(var(--spacing) * 2);
    }
  }

  .title {
    margin-bottom: calc(var(--spacing) * 2);

    @include md {
      margin-bottom: var(--spacing);
    }
  }

  &:focus-within,
  &:hover {
    --teaser--background-color: var(--color--mono-100);
  }

  &:hover * > .ctaWrapper > button {
    color: var(--button--text-color);
    --button--background-color: var(--button--main-color);
  }

  .ctaWrapper {
    margin-top: calc(var(--spacing) * 3);

    > button {
      max-width: 100%;
    }

    @include md {
      margin-top: calc(var(--spacing) * 2);
    }
  }
}
