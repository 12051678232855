@import '../../styles/breakpoints';

.root {
  --container--horiz-padding: calc(var(--spacing) * 11);
  --container--size: 1440px;
  width: 100%;
  margin: 0 auto;
  padding-left: var(--container--horiz-padding);
  padding-right: var(--container--horiz-padding);
  max-width: calc(var(--container--size));

  @include lg {
    --container--horiz-padding: 50px;
  }

  @include md {
    --container--horiz-padding: 32px;
  }

  @include sm {
    --container--horiz-padding: 16px;
  }

  @include xs {
    --container--horiz-padding: var(calc(--spacing) * 2);
  }

  &.fullWidth {
    --container--horiz-padding: 0;
  }
}
