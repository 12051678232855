@import '../../styles/breakpoints';
@import 'node_modules/@dreipol/pusch-components/scss/mixins';

.root {
  display: flex;
  flex-direction: column;
  gap: calc(var(--spacing) * 6);
  max-width: 616px;
  margin: 0 auto;

  .contactDetailsContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: calc(var(--spacing) * 3);
    flex-wrap: wrap;

    .name {
      margin-top: var(--spacing);
      margin-bottom: 2px;
    }
  }

  .textContainer {
    > p {
      margin: 0;
    }
  }

  .formContainer {
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing) * 3);

    .recaptcha {
      margin-top: calc(var(--spacing) * -2.5);

      .inlineLinks {
        display: inline-block;
      }
    }

    .message textarea {
      height: 132px;
    }

    .ctaButton {
      margin-top: calc(var(--spacing) * 3);
      width: fit-content;
    }
  }

  .successMessage {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    animation: fadein 0.7s ease-in-out;

    svg path {
      fill: var(--color--primary-main);
    }
  }

  @keyframes fadein {
    from {
      opacity: 0;
      transform: translateY(-20%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
