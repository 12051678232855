@import '../../styles/breakpoints';

.root {
  & {
    display: grid;
    grid-template-columns: 164px auto;
    grid-gap: calc(var(--spacing) * 2);
    color: var(--color--text-primary);
    outline: none;
    margin: 0 auto;
    overflow: hidden;

    @include lg {
      width: 480px;
      grid-template-columns: 140px auto;
    }

    @include sm {
      grid-template-columns: 90px auto;
      max-width: 375px;
    }

    @include xs {
      max-width: 100%;
      height: 90px;
    }

    &:focus-within {
      .title {
        text-decoration: underline;
      }
    }
  }

  .image {
    object-fit: cover;
  }

  .title {
    margin-top: calc(var(--spacing) * 0.5);
    margin-bottom: calc(var(--spacing) * 0.5);
  }

  .metaWrapper {
    display: flex;
    align-items: center;
  }

  .indicator {
    margin-left: var(--spacing);
  }

  .label {
    color: var(--color--text-secondary);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 0;

    @include sm {
      -webkit-line-clamp: 1;
    }
  }
}
