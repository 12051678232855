@import '../../styles/breakpoints';
@import 'node_modules/@dreipol/pusch-components/scss/mixins';

.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: calc(var(--spacing) * 3);
  margin-bottom: calc(var(--spacing) * 7);
  padding: 0 calc(var(--spacing) * 4);

  @include md {
    margin-bottom: calc(var(--spacing) * 5);
    gap: calc(var(--spacing) * 2);
  }

  @include xs {
    padding: 0;
  }
}
