@import '../styles/breakpoints';
@import 'node_modules/@dreipol/pusch-components/scss/mixins';

//inline record link css
.textLink {
  display: inline;
  white-space: normal;
  word-break: break-word;
}

.link {
}

.interviewHeadline {
  margin-bottom: 30px;
  width: 100%;
  @include md {
    margin-bottom: 24px;
  }
}

.highlightBoxRoot {
  max-width: 616px;
  width: 100%;
  margin-bottom: calc(var(--spacing) * 7);

  @include md {
    margin-bottom: calc(var(--spacing) * 5);
  }

  .content > * {
    margin-bottom: calc(var(--spacing) * 1.5);
    margin-top: 0 !important;
  }

  .content li::marker {
    font-size: 14px;
  }

  .content ul {
    width: calc(100% - (var(--spacing) * 4));
    margin-left: calc(var(--spacing) * 4);
    margin-top: 0 !important;

    @include xs {
      margin-left: calc(var(--spacing) * 2);
    }
  }

  .content ol {
    margin-top: 0 !important;

    @include xs {
      padding-left: calc(var(--spacing) * 2);
    }
  }

  .content * a > span {
    @include typo-paragraph2;

    @include md {
      @include typo-paragraph2-small;
    }
  }

  .content br {
    display: block;
    content: '';
    margin-top: 24px;

    @include md {
      margin-top: 17px;
    }
  }
}
