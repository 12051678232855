@import '../../styles/breakpoints';

.root {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: calc(var(--spacing) * 3) calc(var(--spacing) * 11);
  position: absolute;
  position: sticky;
  bottom: 0;
  left: 0;
  background-color: white;
  z-index: 30;
  box-shadow: 0px 2px 10px rgba(35, 31, 32, 0.16),
    0px 4px 18px rgba(35, 31, 32, 0.2);

  @include md {
    flex-direction: column;
    gap: calc(var(--spacing) * 5);
    padding: calc(var(--spacing) * 3) calc(var(--spacing) * 4);
  }

  .infoText {
    text-align: left;
  }

  @media print {
    display: none;
  }
}
