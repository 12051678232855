@import '../../styles/breakpoints';

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .header {
    flex: 1 1 auto;
  }

  .main {
    flex: 1 0 auto;
    margin-top: calc(var(--spacing) * 10);
    margin-bottom: calc(var(--spacing) * 15);

    @include md {
      margin-top: calc(var(--spacing) * 5);
      margin-bottom: calc(var(--spacing) * 10);
    }

    @media print {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
  }
}
